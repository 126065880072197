import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    token: localStorage.getItem('token') || null,
    isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
    
  };
  

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.token = action.payload;
      state.isAuthenticated = true;
      localStorage.setItem('token', action.payload);
      localStorage.setItem('isAuthenticated', true);
    },
    signup: (state, action) => {
        state.token = action.payload;
        state.isAuthenticated = true;
        localStorage.setItem('token', action.payload);
        localStorage.setItem('isAuthenticated', 'true');
      },
    logout: (state) => {
      state.token = null;
      state.isAuthenticated = false;
      localStorage.removeItem('token');
      localStorage.setItem('isAuthenticated', false);

    },
  },
});

export const { login, logout,signup } = authSlice.actions;

export default authSlice.reducer;
