import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { LuEye, LuEyeOff } from 'react-icons/lu';
import { backend_url } from '../libs/data';
import { useDispatch } from 'react-redux';
import  {login}  from '../redux/authSlice';
const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [blurEmail, setBlurEmail] = useState(false);
    const [blurPassword, setBlurPassword] = useState(false);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();

    const validate = () => {
        const errors = {};
        if (!email) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email address is invalid';
        }
        if (!password) {
            errors.password = 'Password is required';
        } else if (password.length < 8) {
            errors.password = 'Password must be at least 8 characters';
        }
        return errors;
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        const formErrors = validate();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        try {
            setLoading(true);
            const response = await axios.post(`${backend_url}/api/adminlogin`, {
                email,
                password
            });
            const {token} = response.data;    
            dispatch(login(token)); 
            toast.success("Signed in successfully");
            navigate('/dashboard');
            
        } catch (error) {
            const errorMessage = error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="">
                <div className="grid min-h-screen grid-cols-1 md:grid-cols-2">
                    <div className="bg-black md:flex hidden justify-center">
                        <div className="text-white flex gap-5 flex-col items-center justify-center">
                            <h1 className='font-bold text-4xl'>Join us!</h1>
                            <p className='lg:w-[415px] font-normal leading-7 text-center text-[24px]'>
                                We’re excited to have you join us! Register and elevate your music experience with our vast selection of high-quality beats and professional services.
                            </p>
                        </div>
                    </div>
                    <div className="lg:w-3/4 w-full lg:px-0 px-7 flex flex-col items-center justify-center mx-auto">
                        <h1 className="text-4xl font-bold mb-4">Welcome Back</h1>
                        <form className="space-y-3 w-full" onSubmit={handleLogin}>
                            <div>
                                <label className="block p-1 text-gray-700">Email <span className='text-red-500 font-bold text-xl'>*</span></label>
                                <input
                                    type="email"
                                    onFocus={() => setBlurEmail(true)}
                                    onBlur={() => setBlurEmail(false)}
                                    onChange={(e) => { setEmail(e.target.value); setErrors({ ...errors, email: '' }); }}
                                    className={`w-full px-2 focus:outline-none outline-none py-3 border-2 border-opacity-60 ${blurEmail ? "border-[#FF3E41] text-[#FF3E41]" : "border-[#D0D0D0]"} rounded-md transition-border duration-500`}
                                    placeholder="Enter Your Email"
                                />
                                {errors.email && <div className="text-red-500 text-sm">{errors.email}</div>}
                            </div>
                            <div className='relative'>
                                <label className="block p-1 text-gray-700">Password <span className='text-red-500 font-bold text-xl'>*</span></label>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    onFocus={() => setBlurPassword(true)}
                                    onBlur={() => setBlurPassword(false)}
                                    onChange={(e) => { setPassword(e.target.value); setErrors({ ...errors, password: '' }); }}
                                    className={`w-full px-2 focus:outline-none outline-none py-3 border-2 border-opacity-60 ${blurPassword ? "border-[#FF3E41] text-[#FF3E41]" : "border-[#D0D0D0]"} rounded-md transition-border duration-500`}
                                    placeholder="Enter Your Password"
                                />
                                {showPassword ? <LuEyeOff size={20} onClick={() => { setShowPassword(!showPassword) }}
                                    className='text-[#D0D0D0] cursor-pointer absolute top-[58%] right-5' />
                                    :
                                    <LuEye size={20} onClick={() => { setShowPassword(!showPassword) }}
                                        className='text-[#D0D0D0] cursor-pointer absolute top-[58%] right-5' />}
                                {errors.password && <div className="text-red-500 text-sm">{errors.password}</div>}
                            </div>
                            <div className="flex items-center justify-between py-3">
                                <div className="flex items-center gap-2">
                                    <input type="checkbox" className="h-4 w-4 text-red-500 focus:ring-red-400 border-gray-300 accent-[#FF3E41] rounded " />
                                    <label className="block text-black font-medium text-sm">
                                        Remember me
                                    </label>
                                </div>
                                <div className="">
                                    <button className='text-red-500'>Forgot Password</button>
                                </div>
                            </div>
                            <button type="submit" className="w-full font-bold bg-red-500 text-white py-2 rounded hover:bg-red-600 transition duration-200">{loading ? "Logging..." : "Login"}</button>
                        </form>
                        {/* <div className="flex w-full items-center gap-5 py-5">
                            <div className="flex-grow border w-1/4 border-black"></div>
                            <span className="text-black text-base">or</span>
                            <div className="flex-grow border w-1/4 border-black"></div>
                        </div>
                        <button className="w-full text-gray-700 flex items-center justify-center">
                            Register with Google
                            <img src={google} alt="Google" className="h-6 w-6 mr-2" />
                        </button> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
