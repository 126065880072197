import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import beatReducer from './beatSlice';
import userReducer from './userSlice';
import analyticsReducer from './analyticsSlice'
export const store = configureStore({
  reducer: {
    auth: authReducer,
    beats:beatReducer,
    users:userReducer,
    analytics:analyticsReducer
  },
});
