import React, { useState } from 'react';
import UserNavbar from '../components/UserNavbar';
import UserDashboardSideBar from '../components/UserDasboardSideBar'


const UserLayout = ({ children }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <div className="flex flex-col min-h-screen">
            <UserNavbar toggleSidebar={toggleSidebar} />
            <div className="md:flex md:flex-grow  relative">
                <UserDashboardSideBar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
                <main className="bg-black min-h-screen text-white flex-grow">
                    {children}
                </main>
            </div>
        </div>
    );
};

export default UserLayout;
