import React, {  useState } from 'react';
import UserLayout from '../../layout/UserLayout';
import Cards from '../../components/Cards';
import musicIcon from '../../assets/musicIcon.svg';
import Section from '../../components/Section';
import { LoaderComp } from '../../components/UI/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { BeatEditModel } from '../../components/UI/BeatEditModel';
import { useNavigate } from 'react-router-dom';
import { updateBeatId, updateCategory } from '../../redux/analyticsSlice';

const MusicItem = ({ beat, onEditClick,index }) => {
    const navigate = useNavigate(); 
    const dispatch = useDispatch(); 

    const handleClick = () => {
        dispatch(updateCategory('beats'));
        dispatch(updateBeatId(beat._id));
        navigate('/admin/analytics');
    };
    return (
        <div className="flex py-2 items-center justify-between text-white rounded-lg cursor-pointer" >
            <div className="grid lg:grid-cols-12 lg:bg-black bg-[#141414] grid-cols-1 rounded-lg w-full items-center">
                <div className="text-lg lg:col-span-1 py-2 md:bg-[#141414] lg:h-20 lg:w-[4vw] justify-center rounded-lg flex flex-col items-center font-bold">{index}</div>
                <div className="lg:flex-row flex-col flex gap-[8%] items-center w-full bg-[#141414] py-4 px-4 rounded-lg lg:col-span-11">
                    <img src={musicIcon} alt="" className='h-10 w-10' onClick={handleClick}/>
                    <div className='flex items-center gap-3 w-[20%] min-w-0 justify-start'onClick={handleClick}>
                        <div className="font-bold">Name</div>
                        <div className='text-[15px]'>{beat.beatTitle}</div>
                    </div>
                    {/* <div className='flex gap-3 w-[10%] min-w-0'>
                        <div className="font-bold">Artist</div>
                        <div>{beat.artistName}</div>
                    </div> */}
                    <div className='flex gap-3 w-[10%] min-w-0' onClick={handleClick}>
                        <div className="font-bold">BPM</div>
                        <div>{beat.bpm}</div>
                    </div>
                    <div className='flex md:flex-row flex-col gap-3  w-[30%] min-w-0 items-center justify-start' onClick={handleClick}>
                        <div className="font-bold text-left whitespace-nowrap">Tags</div>
                        <div className="flex gap-2">
                            {beat.tags.map((tag, index) => (
                                <span key={index} className="bg-black px-2 py-1 rounded-md text-sm truncate">
                                    {tag}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className="flex items-center w-[20%]">
                        <button onClick={() => onEditClick(beat)} className="bg-red-500 hover:scale-110 hover:transition-transform duration-200 py-1 px-6 rounded-md">
                            Edit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const BeatManagement = () => {
    const [selectedBeat, setSelectedBeat] = useState(null);
    const [isEditModelOpen, setIsEditModelOpen] = useState(false);
    const { beats, loading } = useSelector((state) => state.beats);

    const handleEditClick = (beat) => {
        setSelectedBeat(beat);
        setIsEditModelOpen(true);
    };
  

    return (
        <>
            <UserLayout>
                <Section>
                    {loading ? (
                        <LoaderComp />
                    ) : (
                        <div className="px-5 md:px-14">
                            <div className="py-8">
                                <h1 className="text-2xl font-bold text-white">Beats Management</h1>
                            </div>
                            <div className="flex py-4 justify-between">
                                <h2 className="text-xl text-[#9F9F9F]">Leases</h2>
                                <button className="text-[#9F9F9F] hover:bg-[#9F9F9F] hover:text-white px-2 py-1">
                                    See More
                                </button>
                            </div>
                            <div className="">
                                {beats && beats
                                    .filter((beat) => beat.beatType !== 'Exclusive')
                                    .map((item, index) => (
                                        <MusicItem key={index} index={index+1} beat={item} onEditClick={handleEditClick} />
                                    ))}
                            </div>
                            <div className="flex py-4 pt-8 justify-between">
                                <h2 className="text-xl text-[#9F9F9F]">Exclusive </h2>
                                <button className="text-[#9F9F9F] hover:bg-[#9F9F9F] hover:text-white px-2 py-1">
                                    See More
                                </button>
                            </div>
                            <div className="grid md:grid-cols-2 lg:grid-cols-4 grid-cols-1 gap-5">
                               {beats
                                    .filter((beat) => beat.beatType === "Exclusive")
                                    .map((item, index) => (
                                        <Cards key={index} beat={item} onEditClick={handleEditClick} />
                                    ))}
                              
                            </div>
                        </div>
                    )}
                    {isEditModelOpen && selectedBeat && (
                        <BeatEditModel beats={selectedBeat} setOpenEditBeatModel={setIsEditModelOpen}/>
                    )}
                </Section>
            </UserLayout>
        </>
    );
};

export default BeatManagement;
