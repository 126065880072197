import axios from 'axios';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FaTimes } from 'react-icons/fa';
import { backend_url } from '../libs/data';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { fetchBeats } from '../redux/beatSlice';

const BeatForm = ({ beatType, onClose, setSelectedBeatType }) => {
    const [beatTitle, setBeatTitle] = useState('');
    const [bpm, setBpm] = useState('');
    const [mp3FileLink, setMp3FileLink] = useState('');
    const [wavFileLink, setWavFileLink] = useState('');
    const [zipFileLink, setZipFileLink] = useState('');
    const [releaseDate, setReleaseDate] = useState('');
    const [price, setPrice] = useState('');
    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingbeat, setLoadingBeat] = useState(false);
    const [errors, setErrors] = useState({});
    const [countdownEndTime, setCountdownEndTime] = useState('');
    const [countdown, setCountdown] = useState(null);

    const dispatch = useDispatch();

    const [streamingMp3File, setStreamingMp3File] = useState(null);
    const [streamingMp3FileLink, setStreamingMp3FileLink] = useState('');

    useEffect(() => {
        if (countdownEndTime) {
            const now = new Date().getTime();
            const endTime = new Date(countdownEndTime).getTime();
            const distance = endTime - now;

            if (distance > 0) {
                const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);

                setCountdown({
                    days,
                    hours,
                    minutes,
                    seconds
                });
            } else {
                setCountdown({
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0
                });
            }
        }
    }, [countdownEndTime]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();  
            const newTag = inputValue.trim();

            if (newTag && !tags.includes(newTag)) {
                setTags([...tags, newTag]);
                setInputValue('');
            }
        }
    };

    const validateForm = () => {
        let errors = {};

        if (!beatTitle) errors.beatTitle = 'Beat title is required';
        if (!bpm || isNaN(bpm)) errors.bpm = 'BPM must be a number';
        if (!mp3FileLink && !wavFileLink && !zipFileLink) {
            errors.fileLink = 'At least one file link is required';
        }
        if (!releaseDate) errors.releaseDate = 'Release date is required';
        if (!streamingMp3FileLink) errors.streamingMp3FileLink = 'Streaming File link is required';
        if ((!price || isNaN(price))) errors.price = 'Price must be a number';

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleRemoveTag = (tagToRemove) => {
        setTags(tags.filter(tag => tag !== tagToRemove));
    };

    const handleUploadMp3 = async () => {
        if (!streamingMp3File) {
          toast.error("Please select a streaming MP3 file to upload");
          return;
        }
      
        try {
          setLoadingBeat(true);
      
          // Step 1: Get the signed URL from the backend
          const fileName = streamingMp3File.name;
          const fileType = streamingMp3File.type;
      
          const signedUrlResponse = await axios.post(`${backend_url}/generate-signed-url`, {
            fileName,
            fileType,
          });
      
          const { url: signedUrl } = signedUrlResponse.data;
      
          // Step 2: Upload the file directly to the signed URL
          await axios.put(signedUrl, streamingMp3File, {
            headers: {
              'Content-Type': fileType,
            },
          });
      
          // Step 3: Generate the public URL for the uploaded file
          const publicUrl = `https://storage.googleapis.com/vip_beats/${fileName}`;
          setStreamingMp3FileLink(publicUrl);
      
          toast.success("Streaming MP3 uploaded successfully");
        } catch (error) {
          const errorMessage = error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
          toast.error(errorMessage);
        } finally {
          setLoadingBeat(false);
        }
      };
      

    const handleSubmit = async(event) => {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }
        const beatsData = {
            beatTitle,
            bpm,
            mp3FileLink,
            wavFileLink,
            zipFileLink,
            releaseDate,
            tags,
            price,
            beatType,
            countdownEndTime,
            streamingMp3FileLink
        }
        try {
            setLoading(true);
            await axios.post(`${backend_url}/api/addbeats`, {
                beatsData
            });
            toast.success("Beat Added successfully");
        } catch (error) {
            const errorMessage = error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
            toast.error(errorMessage);
        } finally {
            setLoading(false);
            onClose();
            dispatch(fetchBeats())
            setSelectedBeatType(null);
        }
    };

    const handleFocus = (field) => {
        setErrors(prevErrors => ({ ...prevErrors, [field]: null }));
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-[#141414] min-h-[80%] max-h-[80%] w-[30%] p-6 rounded-lg m-6 overflow-y-auto custom-scrollbar">
                <div className="flex items-center justify-between">
                    <div></div>
                    <h1 className='text-white font-bold flex text-center'>Add New Beat</h1>
                    <FaTimes onClick={() => {
                        onClose();
                        setSelectedBeatType(null);
                    }} className='cursor-pointer text-white' />
                </div>
                <h2 className="text-base text-white py-3 text-center font-bold">{`${beatType} Beat Form`}</h2>
                <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-1">
                        <label className='text-[#FFFFFF]'>Beat Title</label>
                        <input
                            type="text"
                            placeholder="Enter beat title"
                            className="p-2 border rounded-md"
                            value={beatTitle}
                            onFocus={()=>{handleFocus("beatTitle")}}
                            onChange={(e) => setBeatTitle(e.target.value)}
                        />
                        {errors.beatTitle && <p className="text-red-500 text-sm">{errors.beatTitle}</p>}
                    </div>
                   
                    <div className="flex flex-col gap-1">
                        <label className='text-[#FFFFFF]'>BPM</label>
                        <input
                            type="text"
                            placeholder="Enter BPM"
                            className="p-2 border rounded-md"
                            value={bpm}
                            onChange={(e) => setBpm(e.target.value)}
                            onFocus={()=>{handleFocus("bpm")}}
                        />
                        {errors.bpm && <p className="text-red-500 text-sm">{errors.bpm}</p>}
                    </div>
                    <div className="flex flex-col gap-1">
                        <label className='text-[#FFFFFF]'>.MP3 File Link</label>
                        <input
                            type="text"
                            placeholder="Enter .MP3 file link"
                            className="p-2 border rounded-md"
                            value={mp3FileLink}
                            onFocus={()=>{handleFocus("mp3FileLink")}}
                            onChange={(e) => setMp3FileLink(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-col gap-1">
                        <label className='text-[#FFFFFF]'>.WAV File Link</label>
                        <input
                            type="text"
                            placeholder="Enter .WAV file link"
                            className="p-2 border rounded-md"
                            value={wavFileLink}
                            onFocus={()=>{handleFocus("wavFileLink")}}
                            onChange={(e) => setWavFileLink(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-col gap-1">
                        <label className='text-[#FFFFFF]'>.ZIP File Link</label>
                        <input
                            type="text"
                            placeholder="Enter .ZIP file link"
                            className="p-2 border rounded-md"
                            value={zipFileLink}
                            onFocus={()=>{handleFocus("zipFileLink")}}
                            onChange={(e) => setZipFileLink(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-col gap-1">
                        <label className='text-[#FFFFFF]'>Release Date</label>
                        <input
                            type="date"
                            className="p-2 border rounded-md"
                            value={releaseDate}
                            onFocus={()=>{handleFocus("releaseDate")}}
                            onChange={(e) => setReleaseDate(e.target.value)}
                        />
                        {errors.releaseDate && <p className="text-red-500 text-sm">{errors.releaseDate}</p>}
                    </div>
                    <div className="flex flex-col gap-1">
                        <label className='text-[#FFFFFF]'>Tags</label>
                        <input
                            type="text"
                            placeholder="Enter tags"
                            className="p-2 border rounded-md"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyDown={handleKeyDown}
                            onFocus={() => handleFocus('tags')}
                        />
                        <div className="flex flex-wrap gap-1">
                            {tags.map((tag, index) => (
                                <div
                                    key={index}
                                    className="bg-gray-200 text-gray-800 px-2 py-1 rounded-full flex items-center gap-1"
                                >
                                    {tag}
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleRemoveTag(tag);
                                        }}
                                        className="text-red-500 hover:text-red-700"
                                    >
                                        &times;
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                  
                        <div className="flex flex-col gap-1">
                            <label className='text-[#FFFFFF]'>Price</label>
                            <input
                                type="text"
                                placeholder="Enter price"
                                className="p-2 border rounded-md"
                                value={price}
                                onFocus={() => handleFocus('price')}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                            {errors.price && <p className="text-red-500 text-sm">{errors.price}</p>}
                        </div>
                    
                    {beatType === 'Exclusive' && (
                        <div className="flex flex-col gap-1">
                            <label className='text-[#FFFFFF]'>Countdown End Time</label>
                            <input
                                type="datetime-local"
                                className="p-2 border rounded-md"
                                value={countdownEndTime}
                                onChange={(e) => setCountdownEndTime(e.target.value)}
                            />
                        </div>
                    )}
                        <div className="flex flex-col gap-1">
                        <label className='text-[#FFFFFF]'>Upload Streaming MP3 File</label>
                        <input
                            type="file"
                            accept=".mp3"
                            className='text-white'
                            onChange={(e) => setStreamingMp3File(e.target.files[0])}
                        />
                        <button
                            type="button"
                            onClick={handleUploadMp3}
                           
                            className="bg-blue-500 text-white py-2 px-4 rounded mt-2"
                            disabled={loading || loadingbeat}
                        >
                            {loadingbeat ? 'Uploading...' : 'Upload MP3'}
                        </button>
                    </div>
                    <div className="flex flex-col gap-1">
                        <label className='text-[#FFFFFF]'>Streaming mp3 Url</label>
                        <input
                            type="text"
                            placeholder="Streaming Link"
                            className="p-2 border rounded-md"
                            value={streamingMp3FileLink}
                            disabled
                        />
                            {errors.streamingMp3FileLink && <p className="text-red-500 text-sm">{errors.streamingMp3FileLink}</p>}

                    </div>
                    {beatType === 'Exclusive' && countdown && (
                        <div className="text-white py-2">
                            <p className="text-xl font-bold">
                                {countdown.days} Days : {countdown.hours} Hours : {countdown.minutes} Min : {countdown.seconds} Sec
                            </p>
                        </div>
                    )}
                    <div className="py-2">
                        <button type="submit" className="bg-red-500 w-full text-white py-2 px-4 rounded-md hover:bg-red-600" disabled={loading || loadingbeat}>
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
const BeatTypeModal = ({ isOpen, onClose }) => {
    const [selectedBeatType, setSelectedBeatType] = useState(null);

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="absolute inset-0 flex items-center 2xl:top-0 top-[24rem] md:top-[14rem]  justify-center z-50">
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"></div>
            <div className="relative shadow-lg w-full px-2 max-w-sm z-10">
                {selectedBeatType ? (
                    <BeatForm beatType={selectedBeatType} setSelectedBeatType={setSelectedBeatType} onClose={onClose} />
                ) : (
                    <div className="bg-white p-4 rounded-lg" >
                        <div className="py-4 flex justify-between items-center">
                            <h2 className="text-xl font-bold">Select Beat Type</h2>
                            <FaTimes onClick={onClose} className='cursor-pointer'/>
                        </div>
                        <div className="flex flex-col gap-4">
                            <button
                                className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600"
                                onClick={() => setSelectedBeatType('Exclusive')}
                            >
                                Exclusive Beat
                            </button>
                            <button
                                className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600"
                                onClick={() => setSelectedBeatType('Non-Exclusive')}
                            >
                                Non-Exclusive Beat
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>,
        document.body
    );
};

export default BeatTypeModal;
