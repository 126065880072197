import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import axios from "axios";
import { useSelector } from "react-redux";
import musicIcon from "../assets/musicIcon.svg";
import { backend_url } from "../libs/data";
import { useSearchParams } from "react-router-dom";

const chartOptions = {
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        color: "#fff",
      },
      ticks: {
        color: "#fff",
        stepSize: 100,
        callback: function (value) {
          return value;
        },
      },
    },
    x: {
      beginAtZero: true,
      ticks: {
        color: "#fff",
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

const Card = ({
  title,
  count,
  chartData,
  selectedRange,
  handleRangeChange,
}) => (
  <div className="bg-[#141414] text-white p-4 rounded-lg">
    <div className="flex items-center justify-between mb-4">
      <h2 className="text-lg">{title}</h2>
      <select
        value={selectedRange}
        onChange={handleRangeChange}
        className="bg-transparent outline-none cursor-pointer "
      >
        <option value={7} className="text-black ">
          Last 7 days
        </option>
        <option value={14} className="text-black ">
          Last 14 days
        </option>
        <option value={30} className="text-black ">
          Last 30 days
        </option>
        <option value={90} className="text-black ">
          Last 90 days
        </option>
      </select>{" "}
    </div>
    <div className="mb-4 text-3xl">{count}</div>
    <Line data={chartData} options={chartOptions} />
  </div>
);

const ChartsCards = ({ selectedCategory, category, showAll }) => {
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");
  const [vipUserData, setVipUserData] = useState({ data: [], labels: [] });
  const [freeUserData, setFreeUserData] = useState({ data: [], labels: [] });
  const [vipSalesData, setVipSalesData] = useState({ data: [], labels: [] });
  const [exclusiveSalesData, setExclusiveData] = useState({
    data: [],
    labels: [],
  });
  const [stemSalesData, setStemData] = useState({ data: [], labels: [] });
  const [totalSalesData, setTotalSalesData] = useState({
    data: [],
    labels: [],
  });
  const [totalNumberofPlaysByBeat, setTotalNumberofPlaysByBeat] = useState({
    data: [],
    labels: [],
  });
  const [totalNumberofFavByBeat, setTotalNumberofFavByBeat] = useState({
    data: [],
    labels: [],
  });
  const [mp3downloadBeats, setmp3downloadBeats] = useState({
    data: [],
    labels: [],
  });
  const [wavdownloadBeats, setwavdownloadBeats] = useState({
    data: [],
    labels: [],
  });
  const [stemSalesBeats, setstemSalesBeats] = useState({
    data: [],
    labels: [],
  });
  const [PlayBackTimeBeats, setPlayBackTimeBeats] = useState({
    data: [],
    labels: [],
  });

 
  const [beatPlayedUser, setBeatPlayed] = useState({ data: [], labels: [] });
  const [totalFavUser, settotalFavUser] = useState({ data: [], labels: [] });
  const [totalwavdownloadUser, settotalwavdownloadUser] = useState({
    data: [],
    labels: [],
  });
  const [totalmp3downloadUser, settotalmp3downloadUser] = useState({
    data: [],
    labels: [],
  });
  const [totalStemSalesUser, settotalStemSalesUser] = useState({
    data: [],
    labels: [],
  });
  const [userDetail, setUserDetail] = useState({
    purchaseHistory: [],
    user: {},
  });
  const [error, setError] = useState(null);
  const { beatId, userId } = useSelector((state) => state.analytics);
  const [vipSalesDays, setVipSalesDays] = useState(7);
  const [UserDays, setUserDays] = useState(7);
  const [exclusiveDays, setExclusiveDays] = useState(7);
  const [stemDays, setStemDays] = useState(7);
  const [totalSalesDays, settotalSalesDays] = useState(7);
  const [totalPlayBeatDays, setTotalPlayBeatDays] = useState(7);
  const [totalFavBeatDays, setTotalFavBeatDays] = useState(7);
  const [totalMp3BeatDays, setTotalMp3BeatDays] = useState(7);
  const [totalWavBeatDays, setTotalWavBeatDays] = useState(7);
  const [totalStemBeatDays, setTotalStemBeatDays] = useState(7);
  const [totalPlayBackBeatDays, setTotalPlayBackBeatDays] = useState(7);
  const [totalPlayedBeatUserDays, setTotalPlayedBeatUserDays] = useState(7);
  const [totalFavByUserDays, setTotalFavByUserDays] = useState(7);
  const [totalWavDownloadByUserDays, setTotalWavDownloadByUser] = useState(7);
  const [totalMp3DownloadByUserDays, setTotalMp3DownloadByUserDays] =
    useState(7);
  const [totalStemSalesByUserDays, setTotalStemSalesByUserDays] = useState(7);
  const [sessionInfo, setSessioninfo] = useState({});
  const [data,setData] = useState([])
  async function fetchData(
    url,
    beatId = "",
    userId = "",
    days = 7,
    fileType = ""
  ) {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let requestUrl = `${backend_url}/api/statistics/${url}?days=${days}`;

    if (beatId) requestUrl += `&beatId=${beatId}`;
    if (userId) requestUrl += `&userId=${userId}`;
    if (fileType) requestUrl += `&fileType=${fileType}`;
    const response = await axios.get(requestUrl, config);
    return response.data;
  }

  console.log("ff",PlayBackTimeBeats)
  const GetSession = async (id) => {
    try {
      const res = await axios.get(
        `${backend_url}/api/user/getlogtime?userId=${id}`
      );
      setSessioninfo({
        totalLoggedInTime: res.data.totalLoggedInTime,
        lastSessionDate: res.data.lastSessionDate,
      });
    } catch (error) {}
  };
  useEffect(() => {
    if (id) {
      GetSession(id);
    }
  }, [id]);

  const GetDD = async()=>{
    const data = await fetchData("playback/beat", beatId, "", totalPlayBackBeatDays)
  setData(data)
  }
  console.log("ddddd",data)


useEffect(()=>{
  function Labels(data, days, metricType) {
    const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const labels = [];
    const adjustedData = [];

    // Define a mapping for metric types
    const metricMap = {
      count: "count",
      totalAmount: "totalAmount",
    };

    const selectedMetric = metricMap[metricType];

    // Create a map to hold the data by date
    const dataMap = new Map();
    data.forEach((item) => {
      const date = new Date(item.date);
      const dateKey = date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
      dataMap.set(dateKey, item[selectedMetric] || 0);
    });

    // Get today's date
    const today = new Date();

    if (days <= 7) {
      for (let i = 0; i < 7; i++) {
        const pastDate = new Date(today);
        pastDate.setDate(today.getDate() - i);
        const dateKey = pastDate.toISOString().split("T")[0];

        labels.push(weekdays[pastDate.getDay()]);
        adjustedData.push(dataMap.get(dateKey) || 0); // Use the mapped data or 0 if not available
      }
    } else if (days <= 14) {
      for (let i = 0; i < days; i++) {
        const pastDate = new Date(today);
        pastDate.setDate(today.getDate() - i);
        const dateKey = pastDate.toISOString().split("T")[0];

        labels.push(weekdays[pastDate.getDay()]);
        adjustedData.push(dataMap.get(dateKey) || 0);
      }
    } else if (days <= 30) {
      const step = Math.ceil(days / 4);
      for (let i = 0; i < 4; i++) {
        const startIndex = i * step;
        const endIndex = Math.min((i + 1) * step, data.length);
        const weekData = data.slice(startIndex, endIndex);
        const weekSum = weekData.reduce(
          (acc, d) => acc + (d[selectedMetric] || 0),
          0
        );
        labels.push(`W${i + 1}`);
        adjustedData.push(weekSum);
      }
    } else if (days === 90) {
      const weekRanges = [
        { label: "W1", start: 0, end: 4 },
        { label: "W4", start: 4, end: 8 },
        { label: "W8", start: 8, end: 12 },
        { label: "W12", start: 12, end: 13 },
      ];
      weekRanges.forEach((range) => {
        const weekData = data.slice(range.start * 7, range.end * 7);
        const weekSum = weekData.reduce(
          (acc, d) => acc + (d[selectedMetric] || 0),
          0
        );
        labels.push(range.label);
        adjustedData.push(weekSum);
      });
    }

    return { labels, adjustedData };
  }
  if (data) {

    const getTotalPlayBackTimeBeatDatas = Labels(
      data,
      totalPlayBackBeatDays,
      "count"
    );
    const totalPlayBackBeatLabel = getTotalPlayBackTimeBeatDatas.labels;
    const totalPlayBackBeatData =
      getTotalPlayBackTimeBeatDatas.adjustedData;
      setPlayBackTimeBeats({
        data: totalPlayBackBeatData,
        labels: totalPlayBackBeatLabel,
      });
  }
},[data])

  useEffect(() => {
    GetDD()
    async function fetchUsers() {
      try {
        const fetchWithoutIds = [
          fetchData("vipsale", "", "", vipSalesDays), // Doesn't require userId or beatId
          fetchData("exclusivesale", "", "", exclusiveDays),
          fetchData("stemsale", "", "", stemDays),
          fetchData("salevolume", "", "", totalSalesDays),
          fetchData("total/user", "", "", UserDays), // Only UserDays is required
        ];

        const fetchWithBeatId = beatId
          ? [
              fetchData("play/beat", beatId, "", totalPlayBeatDays),
              fetchData("favorites/daily/beat", beatId, "", totalFavBeatDays),
              fetchData("download/beat", beatId, "", totalMp3BeatDays, "mp3"),
              fetchData("download/beat", beatId, "", totalWavBeatDays, "wav"),
              fetchData("stemsale/beat", beatId, "", totalStemBeatDays),
              fetchData("playback/beat", beatId, "", totalPlayBackBeatDays),
            ]
          : [];

        const fetchWithUserId = userId
          ? [
              fetchData("purchaseHistory", "", userId), // Requires only userId

              fetchData("play/user", "", userId, totalPlayedBeatUserDays),
              fetchData("favorites/daily/user", "", userId, totalFavByUserDays),
              fetchData(
                "download/user",
                "",
                userId,
                totalWavDownloadByUserDays,
                "wav"
              ),
              fetchData(
                "download/user",
                "",
                userId,
                totalMp3DownloadByUserDays,
                "mp3"
              ),
              fetchData("stemsale/user", "", userId, totalStemSalesByUserDays),
            ]
          : [];

        const [
          vipData,
          exclusiveData,
          stemData,
          totalSalesDatas,
          totalUser,
          totalPLayBeatsData,
          totalFavBeatsData,
          totalMp3BeatsData,
          totalWavBeatsData,
          totalStemSalesBeatsData,
          totalPlayBackBeatsData,
          userDetailsData,
          totalPlayedBeatUserData,
          totalFavUserData,
          totalWavDownloadUserData,
          totalmp3DownloadUserData,
          totalStemSalesUserData,
        ] = await Promise.all([
          ...fetchWithoutIds,
          ...fetchWithBeatId,
          ...fetchWithUserId,
        ]);
      
        try {
          setUserDetail({
            purchaseHistory: userDetailsData.purchaseHistory.purchases,
            user: userDetailsData.user,
          });
        } catch (error) {
          if (error.response && error.response.status === 404) {
            setError(error.response.data.message); // Handle specific error
          }
        }
        function Labels(data, days, metricType) {
          const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
          const labels = [];
          const adjustedData = [];

          // Define a mapping for metric types
          const metricMap = {
            count: "count",
            totalAmount: "totalAmount",
          };

          const selectedMetric = metricMap[metricType];

          // Create a map to hold the data by date
          const dataMap = new Map();
          data.forEach((item) => {
            const date = new Date(item.date);
            const dateKey = date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
            dataMap.set(dateKey, item[selectedMetric] || 0);
          });

          // Get today's date
          const today = new Date();

          if (days <= 7) {
            for (let i = 0; i < 7; i++) {
              const pastDate = new Date(today);
              pastDate.setDate(today.getDate() - i);
              const dateKey = pastDate.toISOString().split("T")[0];

              labels.push(weekdays[pastDate.getDay()]);
              adjustedData.push(dataMap.get(dateKey) || 0); // Use the mapped data or 0 if not available
            }
          } else if (days <= 14) {
            for (let i = 0; i < days; i++) {
              const pastDate = new Date(today);
              pastDate.setDate(today.getDate() - i);
              const dateKey = pastDate.toISOString().split("T")[0];

              labels.push(weekdays[pastDate.getDay()]);
              adjustedData.push(dataMap.get(dateKey) || 0);
            }
          } else if (days <= 30) {
            const step = Math.ceil(days / 4);
            for (let i = 0; i < 4; i++) {
              const startIndex = i * step;
              const endIndex = Math.min((i + 1) * step, data.length);
              const weekData = data.slice(startIndex, endIndex);
              const weekSum = weekData.reduce(
                (acc, d) => acc + (d[selectedMetric] || 0),
                0
              );
              labels.push(`W${i + 1}`);
              adjustedData.push(weekSum);
            }
          } else if (days === 90) {
            const weekRanges = [
              { label: "W1", start: 0, end: 4 },
              { label: "W4", start: 4, end: 8 },
              { label: "W8", start: 8, end: 12 },
              { label: "W12", start: 12, end: 13 },
            ];
            weekRanges.forEach((range) => {
              const weekData = data.slice(range.start * 7, range.end * 7);
              const weekSum = weekData.reduce(
                (acc, d) => acc + (d[selectedMetric] || 0),
                0
              );
              labels.push(range.label);
              adjustedData.push(weekSum);
            });
          }

          return { labels, adjustedData };
        }

        // VipSlaes
        if (vipData) {
          const VipSalesDatas = Labels(vipData, vipSalesDays, "totalAmount");
          const vipSalesWeek = VipSalesDatas.labels;
          const vipSalesDatass = VipSalesDatas.adjustedData;
          setVipSalesData({
            data: vipSalesDatass,
            labels: vipSalesWeek,
          });
        }
        console.log(totalUser);
        
        // VipUser
        if (totalUser) {
          const vipUserData = totalUser.map((item) => ({
            count: item.VIP,
            date: item.date,
          }));
          const getVipUserData = Labels(vipUserData, UserDays, "count");
          const VipUserData = getVipUserData.adjustedData;
          const VipUserLabel = getVipUserData.labels;
          setVipUserData({
            data: VipUserData,
            labels: VipUserLabel,
          });
        }
        // FreeUser
        if (totalUser) {
          const freeData = totalUser.map((item) => ({
            count: item.free,
            date: item.date,
          }));
          const getFreeUserData = Labels(freeData, UserDays, "count");
          const FreeUserData = getFreeUserData.adjustedData;
          const FreeUserLabel = getFreeUserData.labels;
          setFreeUserData({
            data: FreeUserData,
            labels: FreeUserLabel,
          });
        }
        // Exclusive data
        if (exclusiveData) {
          const exclusiveSalesDatas = Labels(
            exclusiveData,
            exclusiveDays,
            "totalAmount"
          );
          const exclusiveSalesLabel = exclusiveSalesDatas.labels;
          const exclusiveDatas = exclusiveSalesDatas.adjustedData;
          setExclusiveData({
            data: exclusiveDatas,
            labels: exclusiveSalesLabel,
          });
        }

        // Stems data
        if (stemData) {
          const stemSalesDatas = Labels(stemData, stemDays, "totalAmount");
          const stemSalesLabel = stemSalesDatas.labels;
          const stemsDatas = stemSalesDatas.adjustedData;
          setStemData({
            data: stemsDatas,
            labels: stemSalesLabel,
          });
        }
        // Total sales Volume
        if (totalSalesDatas) {
          const getTotalSalesDatas = Labels(
            totalSalesDatas,
            totalSalesDays,
            "totalAmount"
          );
          const totalSalesLabel = getTotalSalesDatas.labels;
          const totalSaleDatas = getTotalSalesDatas.adjustedData;
          setTotalSalesData({
            data: totalSaleDatas,
            labels: totalSalesLabel,
          });
        }
       
       
        // totalPlayBeat
        if (totalPLayBeatsData) {
          const getTotalPlayBeatDatas = Labels(
            totalPLayBeatsData,
            totalPlayBeatDays,
            "count"
          );
          const totalPlayBeatLabel = getTotalPlayBeatDatas.labels;
          const totalPlayBeatDatas = getTotalPlayBeatDatas.adjustedData;
          setTotalNumberofPlaysByBeat({
            data: totalPlayBeatDatas,
            labels: totalPlayBeatLabel,
          });
        }
       
        // Total fav by beat
        if (totalFavBeatsData) {
          const getTotalFavBeatDatas = Labels(
            totalFavBeatsData,
            totalFavBeatDays,
            "count"
          );
          const totalFavBeatLabel = getTotalFavBeatDatas.labels;
          const totalFavBeatDatas = getTotalFavBeatDatas.adjustedData;
          setTotalNumberofFavByBeat({
            data: totalFavBeatDatas,
            labels: totalFavBeatLabel,
          });
        }
       
        // Total mp3 by beat
        if (totalMp3BeatsData) {
          const getTotalmp3BeatDatas = Labels(
            totalMp3BeatsData,
            totalMp3BeatDays,
            "count"
          );
          const totalMp3BeatLabel = getTotalmp3BeatDatas.labels;
          const totalMp3BeatData = getTotalmp3BeatDatas.adjustedData;
          setmp3downloadBeats({
            data: totalMp3BeatData,
            labels: totalMp3BeatLabel,
          });
        }
      if (totalWavBeatsData) {
       // Total wav by beat
       const getTotalWavBeatDatas = Labels(
        totalWavBeatsData,
        totalWavBeatDays,
        "count"
      );
      const totalWavBeatLabel = getTotalWavBeatDatas.labels;
        const totalWavBeatData = getTotalWavBeatDatas.adjustedData; 
        setwavdownloadBeats({
          data: totalWavBeatData,
          labels: totalWavBeatLabel,
        });
      }

        
        // Total stem by beat
    if (totalStemSalesBeatsData) {
      const getTotalStemBeatDatas = Labels(
        totalStemSalesBeatsData,
        totalStemBeatDays,
        "totalAmount"
      );
      const totalStemBeatLabel = getTotalStemBeatDatas.labels;
      const totalStemBeatData = getTotalStemBeatDatas.adjustedData;
      setstemSalesBeats({
        data: totalStemBeatData,
        labels: totalStemBeatLabel,
      });
    }

        

// Total PlayBackTime by beat
      if (data) {

        const getTotalPlayBackTimeBeatDatas = Labels(
          data,
          totalPlayBackBeatDays,
          "count"
        );
        const totalPlayBackBeatLabel = getTotalPlayBackTimeBeatDatas.labels;
        const totalPlayBackBeatData =
          getTotalPlayBackTimeBeatDatas.adjustedData;
          setPlayBackTimeBeats({
            data: totalPlayBackBeatData,
            labels: totalPlayBackBeatLabel,
          });
      }
        
        // Total Played Beat by user
        if (totalPlayedBeatUserData) {
          const getTotalPlayedBeatByUserData = Labels(
            totalPlayedBeatUserData,
            totalPlayedBeatUserDays,
            "count"
          );
          const totalPlayedBeatByUserLabel = getTotalPlayedBeatByUserData.labels;
          const totalPlayedBeatByUserData =
            getTotalPlayedBeatByUserData.adjustedData;
            setBeatPlayed({
              data: totalPlayedBeatByUserData,
              labels: totalPlayedBeatByUserLabel,
            });
        }
       if (totalFavUserData) {
        const getTotalFavByUserData = Labels(
          totalFavUserData,
          totalFavByUserDays,
          "count"
        );
        const totalFavByUserLabel = getTotalFavByUserData.labels;
         const totalFavByUserData = getTotalFavByUserData.adjustedData;
         settotalFavUser({
          data: totalFavByUserData,
          labels: totalFavByUserLabel,
        });
       }
      if (totalWavDownloadUserData) {
          // Total wav downloads by user
          const getTotalWavDownloadByUserData = Labels(
            totalWavDownloadUserData,
            totalWavDownloadByUserDays,
            "count"
          );
          const totalWavDownloadByUserLabel =
            getTotalWavDownloadByUserData.labels;
          const totalWavDownloadByUserData =
            getTotalWavDownloadByUserData.adjustedData;
            settotalwavdownloadUser({
              data: totalWavDownloadByUserData,
              labels: totalWavDownloadByUserLabel,
            });
      }
      
        // Total mp3 downloads by user
        if (totalmp3DownloadUserData) {
          const getTotalMp3DownloadByUserData = Labels(
            totalmp3DownloadUserData,
            totalMp3DownloadByUserDays,
            "count"
          );
          const totalMp3DownloadByUserLabel =
            getTotalMp3DownloadByUserData.labels;
          const totalMp3DownloadByUserData =
            getTotalMp3DownloadByUserData.adjustedData;
            settotalmp3downloadUser({
              data: totalMp3DownloadByUserData,
              labels: totalMp3DownloadByUserLabel,
            });
        }
       
        // Total stem sales by user
        if (totalStemSalesUserData) {
          const getTotalStemSalesByUserData = Labels(
            totalStemSalesUserData,
            totalStemSalesByUserDays,
            "count"
          );
          const totalStemSalesByUserLabel = getTotalStemSalesByUserData.labels;
          const totalStemSalesByUserData =
            getTotalStemSalesByUserData.adjustedData;
            settotalStemSalesUser({
              data: totalStemSalesByUserData,
              labels: totalStemSalesByUserLabel,
            });
        }

      } catch (error) {
        if (error.response && error.response.status === 404) {
          setError(error.response.data.message);
        }
      }
    }

    fetchUsers();
  }, [
    beatId,
    userId,
    vipSalesDays,
    UserDays,
    exclusiveDays,
    stemDays,
    totalSalesDays,
    totalPlayBeatDays,
    totalFavBeatDays,
    totalMp3BeatDays,
    totalWavBeatDays,
    totalStemBeatDays,
    totalPlayBackBeatDays,
    totalPlayedBeatUserDays,
    totalFavByUserDays,
    totalWavDownloadByUserDays,
    totalMp3DownloadByUserDays,
    totalStemSalesByUserDays,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allCards = () => {
    switch (selectedCategory || category) {
      case "global":
        return (
          <>
            <Card
              selectedRange={vipSalesDays}
              handleRangeChange={
                (e) => setVipSalesDays(Number(e.target.value)) // Convert the string to a number
              }
              title="Vip Sales"
              count={`$${vipSalesData.data.reduce((a, b) => a + b, 0)}`}
              chartData={{
                labels: vipSalesData.labels,
                datasets: [
                  {
                    label: "Vip Sales",
                    data: vipSalesData.data,
                    borderColor: "red",
                    borderWidth: 4,
                    backgroundColor: "transparent",
                    fill: false,
                    tension: 0.4,
                    borderJoinStyle: "rounded",
                    pointRadius: 0,
                  },
                ],
              }}
            />
            <Card
              selectedRange={exclusiveDays}
              handleRangeChange={(e) =>
                setExclusiveDays(Number(e.target.value))
              }
              title="Exclusive Sales"
              count={`$${exclusiveSalesData.data.reduce((a, b) => a + b, 0)}`}
              chartData={{
                labels: exclusiveSalesData.labels,
                datasets: [
                  {
                    label: "Exclusive Sales",
                    data: exclusiveSalesData.data,
                    borderColor: "red",
                    borderWidth: 4,
                    backgroundColor: "transparent",
                    fill: false,
                    tension: 0.4,
                    borderJoinStyle: "rounded",
                    pointRadius: 0,
                  },
                ],
              }}
            />
            <Card
              selectedRange={stemDays}
              handleRangeChange={(e) => setStemDays(Number(e.target.value))}
              title="Stems Sales"
              count={`$${stemSalesData.data.reduce((a, b) => a + b, 0)}`}
              chartData={{
                labels: stemSalesData.labels,
                datasets: [
                  {
                    label: "Stems Sales",
                    data: stemSalesData.data,
                    borderColor: "red",
                    borderWidth: 4,
                    backgroundColor: "transparent",
                    fill: false,
                    tension: 0.4,
                    borderJoinStyle: "rounded",
                    pointRadius: 0,
                  },
                ],
              }}
            />
            <Card
              title="Free Users"
              selectedRange={UserDays}
              handleRangeChange={
                (e) => setUserDays(Number(e.target.value)) // Convert the string to a number
              }
              count={freeUserData.data.reduce((a, b) => a + b, 0)}
              chartData={{
                labels: freeUserData.labels,
                datasets: [
                  {
                    label: "Free Users",
                    data: freeUserData.data,
                    borderColor: "red",
                    borderWidth: 4,
                    backgroundColor: "transparent",
                    fill: false,
                    tension: 0.4,
                    borderJoinStyle: "rounded",
                    pointRadius: 0,
                  },
                ],
              }}
            />
            <Card
              selectedRange={UserDays}
              handleRangeChange={
                (e) => setUserDays(Number(e.target.value)) // Convert the string to a number
              }
              title="Vip Users"
              count={vipUserData.data.reduce((a, b) => a + b, 0)}
              chartData={{
                labels: vipUserData.labels,
                datasets: [
                  {
                    label: "VIP Users",
                    data: vipUserData.data,
                    borderColor: "red",
                    borderWidth: 4,
                    backgroundColor: "transparent",
                    fill: false,
                    tension: 0.4,
                    borderJoinStyle: "rounded",
                    pointRadius: 0,
                  },
                ],
              }}
            />
            <Card
              selectedRange={totalSalesDays}
              handleRangeChange={
                (e) => settotalSalesDays(Number(e.target.value)) // Convert the string to a number
              }
              title="Total Sales"
              count={`$${totalSalesData.data.reduce((a, b) => a + b, 0)}`}
              chartData={{
                labels: totalSalesData.labels,
                datasets: [
                  {
                    label: "Total Sales",
                    data: totalSalesData.data,
                    borderColor: "red",
                    borderWidth: 4,
                    backgroundColor: "transparent",
                    fill: false,
                    tension: 0.4,
                    borderJoinStyle: "rounded",
                    pointRadius: 0,
                  },
                ],
              }}
            />
          </>
        );
      case "beats":
        return (
          <>
            <Card
              selectedRange={totalPlayBeatDays}
              handleRangeChange={
                (e) => setTotalPlayBeatDays(Number(e.target.value)) // Convert the string to a number
              }
              title="Total Plays"
              count={totalNumberofPlaysByBeat.data.reduce((a, b) => a + b, 0)}
              chartData={{
                labels: totalNumberofPlaysByBeat.labels,
                datasets: [
                  {
                    label: "Total Plays",
                    data: totalNumberofPlaysByBeat.data,
                    borderColor: "red",
                    borderWidth: 4,
                    backgroundColor: "transparent",
                    fill: false,
                    tension: 0.4,
                    borderJoinStyle: "rounded",
                    pointRadius: 0,
                  },
                ],
              }}
            />
            <Card
              selectedRange={totalFavBeatDays}
              handleRangeChange={
                (e) => setTotalFavBeatDays(Number(e.target.value)) // Convert the string to a number
              }
              title="Total Favorites"
              count={totalNumberofFavByBeat.data.reduce((a, b) => a + b, 0)}
              chartData={{
                labels: totalNumberofFavByBeat.labels,
                datasets: [
                  {
                    label: "Total Favorites",
                    data: totalNumberofFavByBeat.data,
                    borderColor: "red",
                    borderWidth: 4,
                    backgroundColor: "transparent",
                    fill: false,
                    tension: 0.4,
                    borderJoinStyle: "rounded",
                    pointRadius: 0,
                  },
                ],
              }}
            />
            <Card
              selectedRange={totalWavBeatDays}
              handleRangeChange={(e) =>
                setTotalWavBeatDays(Number(e.target.value))
              }
              title="wav Downloads"
              count={wavdownloadBeats.data.reduce((a, b) => a + b, 0)}
              chartData={{
                labels: wavdownloadBeats.labels,
                datasets: [
                  {
                    label: "wav Downloads",
                    data: wavdownloadBeats.data,
                    borderColor: "red",
                    borderWidth: 4,
                    backgroundColor: "transparent",
                    fill: false,
                    tension: 0.4,
                    borderJoinStyle: "rounded",
                    pointRadius: 0,
                  },
                ],
              }}
            />

            <Card
              selectedRange={totalMp3BeatDays}
              handleRangeChange={
                (e) => setTotalMp3BeatDays(Number(e.target.value)) // Convert the string to a number
              }
              title="Mp3 Downloads"
              count={mp3downloadBeats.data.reduce((a, b) => a + b, 0)}
              chartData={{
                labels: mp3downloadBeats.labels,
                datasets: [
                  {
                    label: "Mp3 Downloads",
                    data: mp3downloadBeats.data,
                    borderColor: "red",
                    borderWidth: 4,
                    backgroundColor: "transparent",
                    fill: false,
                    tension: 0.4,
                    borderJoinStyle: "rounded",
                    pointRadius: 0,
                  },
                ],
              }}
            />
            <Card
              selectedRange={totalStemBeatDays}
              handleRangeChange={(e) =>
                setTotalStemBeatDays(Number(e.target.value))
              }
              title="Stem Sales"
              count={`$${stemSalesBeats.data.reduce((a, b) => a + b, 0)}`}
              chartData={{
                labels: stemSalesBeats.labels,
                datasets: [
                  {
                    label: "Stem Sales",
                    data: stemSalesBeats.data,
                    borderColor: "red",
                    borderWidth: 4,
                    backgroundColor: "transparent",
                    fill: false,
                    tension: 0.4,
                    borderJoinStyle: "rounded",
                    pointRadius: 0,
                  },
                ],
              }}
            />
            <Card
              selectedRange={totalPlayBackBeatDays}
              handleRangeChange={(e) =>
                setTotalPlayBackBeatDays(Number(e.target.value))
              }
              title="PlayBack Time"
              count={PlayBackTimeBeats.data.reduce((a, b) => a + b, 0)}
              chartData={{
                labels: PlayBackTimeBeats.labels,
                datasets: [
                  {
                    label: "PlayBack Time",
                    data: PlayBackTimeBeats.data,
                    borderColor: "red",
                    borderWidth: 4,
                    backgroundColor: "transparent",
                    fill: false,
                    tension: 0.4,
                    borderJoinStyle: "rounded",
                    pointRadius: 0,
                  },
                ],
              }}
            />
          </>
        );
      case "users":
        return (
          <>
            <Card
              selectedRange={totalPlayedBeatUserDays}
              handleRangeChange={(e) =>
                setTotalPlayedBeatUserDays(Number(e.target.value))
              }
              title="Beats Played"
              count={beatPlayedUser.data.reduce((a, b) => a + b, 0)}
              chartData={{
                labels: beatPlayedUser.labels,
                datasets: [
                  {
                    label: "Beats Played",
                    data: beatPlayedUser.data,
                    borderColor: "red",
                    borderWidth: 4,
                    backgroundColor: "transparent",
                    fill: false,
                    tension: 0.4,
                    borderJoinStyle: "rounded",
                    pointRadius: 0,
                  },
                ],
              }}
            />
            <Card
              selectedRange={totalFavByUserDays}
              handleRangeChange={(e) =>
                setTotalFavByUserDays(Number(e.target.value))
              }
              title="Favorites"
              count={totalFavUser.data.reduce((a, b) => a + b, 0)}
              chartData={{
                labels: totalFavUser.labels,
                datasets: [
                  {
                    label: "Total Favorites",
                    data: totalNumberofFavByBeat.data,
                    borderColor: "red",
                    borderWidth: 4,
                    backgroundColor: "transparent",
                    fill: false,
                    tension: 0.4,
                    borderJoinStyle: "rounded",
                    pointRadius: 0,
                  },
                ],
              }}
            />
            <Card
              selectedRange={totalWavDownloadByUserDays}
              handleRangeChange={(e) =>
                setTotalWavDownloadByUser(Number(e.target.value))
              }
              title="wav Downloads"
              count={totalwavdownloadUser.data.reduce((a, b) => a + b, 0)}
              chartData={{
                labels: totalwavdownloadUser.labels,
                datasets: [
                  {
                    label: "wav Downloads",
                    data: totalwavdownloadUser.data,
                    borderColor: "red",
                    borderWidth: 4,
                    backgroundColor: "transparent",
                    fill: false,
                    tension: 0.4,
                    borderJoinStyle: "rounded",
                    pointRadius: 0,
                  },
                ],
              }}
            />

            <Card
              selectedRange={totalMp3DownloadByUserDays}
              handleRangeChange={(e) =>
                setTotalMp3DownloadByUserDays(Number(e.target.value))
              }
              title="Mp3 Downloads"
              count={totalmp3downloadUser.data.reduce((a, b) => a + b, 0)}
              chartData={{
                labels: totalmp3downloadUser.labels,
                datasets: [
                  {
                    label: "Mp3 Downloads",
                    data: totalmp3downloadUser.data,
                    borderColor: "red",
                    borderWidth: 4,
                    backgroundColor: "transparent",
                    fill: false,
                    tension: 0.4,
                    borderJoinStyle: "rounded",
                    pointRadius: 0,
                  },
                ],
              }}
            />
            <Card
              selectedRange={totalStemSalesByUserDays}
              handleRangeChange={(e) =>
                setTotalStemSalesByUserDays(Number(e.target.value))
              }
              title="Stem Sales"
              count={`$${totalStemSalesUser.data.reduce((a, b) => a + b, 0)}`}
              chartData={{
                labels: totalStemSalesUser.labels,
                datasets: [
                  {
                    label: "Stem Sales",
                    data: totalStemSalesUser.data,
                    borderColor: "red",
                    borderWidth: 4,
                    backgroundColor: "transparent",
                    fill: false,
                    tension: 0.4,
                    borderJoinStyle: "rounded",
                    pointRadius: 0,
                  },
                ],
              }}
            />

            <div className="flex flex-col gap-5 p-4 text-white rounded-lg ">
              <div className="flex bg-[#141414] justify-between items-center px-6 p-4 rounded-lg">
                <div className="">
                  <span className="text-[32px] mb-4">
                    {sessionInfo?.lastSessionDate}
                  </span>
                  <h2 className="text-xl">Last Session</h2>
                </div>
                <img src={musicIcon} alt="" className="" />
              </div>
              <div className="flex bg-[#141414] justify-between items-center px-6 p-4 rounded-lg">
                <div className="">
                  <span className="text-[32px] mb-4">
                    {sessionInfo?.totalLoggedInTime}
                  </span>
                  <h2 className="text-lg">
                    Total Time <br /> Logged In
                  </h2>
                </div>
                <img src={musicIcon} alt="" className="" />
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const visibleCards = React.useMemo(() => {
    const cards = React.Children.toArray(allCards());
    return showAll ? cards : cards.slice(0, 3);
  }, [showAll, allCards]);

  const renderTable = () => (
    <div className="mt-10 lg:px-2 lg:w-full">
      <div className="overflow-x-auto">
        <table className="min-w-full text-white rounded-lg">
          <thead>
            <tr className="text-left bg-[#141414] border-b border-[#9F9F9F]">
              <th className="p-3">Serial No.</th>
              <th className="p-3">Name</th>
              <th className="p-3">Email</th>
              <th className="p-3">Item</th>
              <th className="p-3">Amount</th>
              <th className="p-3">Paid in</th>
              <th className="p-3">Date</th>
            </tr>
          </thead>
          <tbody className="w-full space-y-2">
            {error && error ? (
              <tr>
                <td colSpan="7" className="p-4 text-center">
                  {error}
                </td>
              </tr>
            ) : (
              userDetail.purchaseHistory.map((item, index) => (
                <tr
                  key={index}
                  className="bg-[#141414] text-[#9F9F9F] rounded-lg"
                >
                  <td className="p-4">{index + 1}</td>
                  <td className="p-4">{userDetail.user?.fullName}</td>{" "}
                  {/* Use item data */}
                  <td className="p-4">{userDetail.user?.email}</td>{" "}
                  {/* Use item data */}
                  <td className="p-4">Beat</td> {/* Use item data */}
                  <td className="p-4">${item.price}</td> {/* Use item data */}
                  <td className="p-4">Credit</td> {/* Use item data */}
                  <td className="p-4">{item.paymentDate}</td>{" "}
                  {/* Use item data */}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <div className="lg:p-7">
      <div className="py-4">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
          {visibleCards}
        </div>
        {selectedCategory === "users" && renderTable()}
      </div>
    </div>
  );
};

export default ChartsCards;
