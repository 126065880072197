import axios from 'axios';
import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { backend_url } from '../libs/data';
import toast from 'react-hot-toast';

const InviteUserModal = ({ isOpen, onClose }) => {
    const [email, setEmail] = useState('');
    const [credits, setCredits] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async(e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const response = await axios.post(`${backend_url}/SendInvite`, {
                email,
                credits
            });
            toast.success("Invite Sent Successfully");
        } catch (error) {
            const errorMessage = error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
            toast.error(errorMessage);
        } finally {
            setLoading(false);
            onClose();
        }
       
    };

    if (!isOpen) return null;

    return (
        <div className="absolute inset-0 flex items-center z-50 justify-center text-white bg-black bg-opacity-50 backdrop-blur-sm">
            <div className="p-6 bg-[#141414] rounded-lg w-full max-w-sm">
                <div className="flex py-3 gap-8 items-center justify-center">
                    <h2 className="text-xl font-bold text-center">Invite User</h2>
                    <FaTimes onClick={onClose} className='cursor-pointer relative lg:left-24 flex justify-end text-end text-white' />
                </div>
                <form onSubmit={handleSubmit} className='flex flex-col gap-2'>
                    <div className="">
                        <label htmlFor="email" className="block text-sm font-medium p-1">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full border text-black rounded-md p-2"
                            required
                        />
                    </div>
                    <div className="">
                        <label htmlFor="credits" className="block text-sm font-medium p-1">Credits</label>
                        <input
                            type="number"
                            id="credits"
                            value={credits}
                            onChange={(e) => setCredits(e.target.value)}
                            className="w-full border border-gray-300 text-black rounded-md p-2"
                            required
                        />
                    </div>
                   
                    <button
                        type="submit"
                        className="w-full  bg-red-500 text-white py-2 mt-5 px-4 rounded-md hover:bg-red-600"
                    >
                        {loading ? "Inviting..." : "Invite"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default InviteUserModal;
