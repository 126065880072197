import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Login from './components/Login'
import Dashboard from './pages/adminpannel/Dashboard'
import BeatManagement from './pages/adminpannel/BeatManagement'
import UserManagement from './pages/adminpannel/UserManagement'
import Analystics from './pages/adminpannel/Analystics'
import { fetchBeats } from './redux/beatSlice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUsers } from './redux/userSlice';


function App() {
  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(fetchBeats()); 
      dispatch(fetchUsers())
  }, [dispatch]);
  return (
    <>
        <Router>
          <Routes>
            <Route path={'/dashboard'} element={<Dashboard />} />
            <Route path={'/'} element={<Login />} />
            {/* <Route element={<PrivateRoute />}> */}
            {/* </Route> */}
            <Route path={'/admin/beats-management'} element={<BeatManagement />} />
            <Route path={'/admin/user-management'} element={<UserManagement />} />
            <Route path={'/admin/analytics'} element={<Analystics />} />
          </Routes>
        </Router>
    </>
  );
}

export default App;
