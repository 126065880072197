import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { backend_url } from '../libs/data';

export const fetchBeats = createAsyncThunk('beats/fetchBeats', async () => {
    const response = await axios.get(`${backend_url}/api/getbeats`);
    return response.data.beats;
});

const beatsSlice = createSlice({
    name: 'beats',
    initialState: {
        beats: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBeats.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchBeats.fulfilled, (state, action) => {
                state.loading = false;
                state.beats = action.payload;
            })
            .addCase(fetchBeats.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default beatsSlice.reducer;
