import { createSlice } from '@reduxjs/toolkit';
import { fetchBeats } from './beatSlice';
import { fetchUsers } from './userSlice';

// Define the initial state for the analytics slice
const initialState = {
    selectedCategory: 'global',
    beatId:"",
    userId:"",
};

// Create the slice with the name 'analytics'
const AnalyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        updateCategory(state, action) {
            state.selectedCategory = action.payload;
        },
        updateBeatId(state, action) {
            state.beatId = action.payload;
        },
        updateUserId(state, action) {
            state.userId = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBeats.fulfilled, (state, action) => {
            if (!state.beatId && action.payload.length > 0) {
                state.beatId = action.payload[action.payload.length - 1]._id; // Assuming beats have an 'id' field
            }
        });
        builder.addCase(fetchUsers.fulfilled, (state, action) => {
            if (!state.userId && action.payload.length > 0) {
                state.userId = action.payload[action.payload.length - 1]._id; // Assuming beats have an 'id' field
            }
        });
    },
    
});

// Export the reducer to be used in the store
export default AnalyticsSlice.reducer;

// Export the action creator to be used in components
export const { updateCategory,updateBeatId ,updateUserId} = AnalyticsSlice.actions;
