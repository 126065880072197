import React from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import avatar from '../assets/avatar.svg';
import searchIcon from '../assets/search.svg';
import logoutIcon from '../assets/logout.svg';
import homeIcon from '../assets/home.svg';
import userIcon from '../assets/user.svg';
import musicIcon from '../assets/music.svg';
import analyticsIcon from '../assets/analytics.svg';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/authSlice';
import toast from 'react-hot-toast';

const adminNavLinks = [
  { src: homeIcon, label: 'Dashboard', path: '/dashboard' },
  { src: musicIcon, label: 'Beats Management', path: '/admin/beats-management' },
  { src: userIcon, label: 'User Management', path: '/admin/user-management' },
  { src: analyticsIcon, label: 'Analytics', path: '/admin/analytics' },
];

const UserDashboardSideBar = ({ sidebarOpen, toggleSidebar }) => {
  const location = useLocation();
  const dispatch = useDispatch()
  const currentPath = location.pathname;
  const navigate = useNavigate()
  const isAdminPanel = currentPath.startsWith('/admin');
  
  const navLinks = adminNavLinks
  const HandleLogout=()=>{
    dispatch(logout())
    toast.success("Logged Out Successfully")
    navigate('/')

  }


  return (
    <div className={`bg-[#141414] text-white fixed left-0 bottom-0 md:relative overflow-y-auto top-0 w-full md:w-64 z-30 transition-transform duration-300 ${sidebarOpen ? 'translate-y-0' : '-translate-y-full md:translate-y-0'}`}>
      <div className="flex flex-col items-center py-8">
       <div className='bg-black w-[100px] h-[100px] rounded-full flex items-center justify-center'>
        <p>AD</p>

       </div>
        <h3 className="text-xl font-bold">Admin</h3>
        {/* <span className="text-sm text-gray-400">Credits: 100 FREE</span> */}
        <button className="md:hidden block absolute top-4 right-4" onClick={toggleSidebar}>
          {sidebarOpen ? (
            <FaTimes className="text-white text-2xl" />
          ) : (
            <FaBars className="text-white text-2xl" />
          )}
        </button>
      </div>
      <div className="px-4 py-5">
        <div className='bg-white flex items-center gap-1 rounded-xl px-2'>
          <img src={searchIcon} alt="Search" className='px-1' />
          <input
            type="text"
            placeholder='Search...'
            className='w-full py-4 text-black bg-transparent rounded-md focus:outline-none'
          />
        </div>
      </div>
      <nav className="flex flex-col px-5 gap-3">
        <div className='flex flex-col gap-5'>
          {navLinks.map((link, index) => (
            <Link
              key={index}
              to={link.path}
              className={`flex gap-2 items-center py-3 px-3 rounded-md cursor-pointer transition-transform duration-300 ${currentPath === link.path ? 'bg-red-500 text-white' : 'text-white hover:bg-red-500'
                }`}
            >
              <img src={link.src} alt={link.label} />
              {link.label}
            </Link>
          ))}
        </div>
        <div className="flex items-center py-20 justify-center w-full">
          <button className="flex items-center justify-center gap-2 py-3 px-4 w-full bg-white rounded-sm text-red-500 hover:scale-110 transition-transform duration-200" onClick={()=>{
            HandleLogout()
          }}>
            <img src={logoutIcon} alt="Logout" />
            <span>Logout</span>
          </button>
        </div>
      </nav>
    </div>
  );
};

export default UserDashboardSideBar;
