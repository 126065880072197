import React, { useEffect, useState } from 'react'
import UserLayout from '../../layout/UserLayout'
import edit from '../../assets/edit.svg'
import trash from '../../assets/trash.svg'
import Section from '../../components/Section'
import { LoaderComp } from '../../components/UI/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { updateCategory, updateUserId } from '../../redux/analyticsSlice'
import { useNavigate } from 'react-router-dom'
import EditUserModal from '../../components/UI/EditUserModel'
import PromoteUserModel from '../../components/UI/PromoteUserModel'
import toast from 'react-hot-toast'
import { fetchUsers } from '../../redux/userSlice'
import axios from 'axios'
import { backend_url } from '../../libs/data'

const UserManagement = () => {
   const { users , loading } = useSelector((state) => state.users)
   const navigate = useNavigate(); 
    const dispatch = useDispatch(); 
    const [Loading, setLoading] = useState(false);
    const [isEditUser,setIsEditUser] = useState(false)
    const [ispromoteUser,setIsPromoteUser] = useState(false)
    const [selectedUser,setSelectedUser] = useState('')

    const handleClick = (id) => {
        dispatch(updateCategory('users'));
        dispatch(updateUserId(id));
        navigate(`/admin/analytics?id=${id}`);
    };
    useEffect(()=>{
        dispatch(fetchUsers())
      },[dispatch])
   
      const HandleCloseEdit = ()=>{
       setIsEditUser(false)
       setSelectedUser('')
      }
      const HandleClosePromote = ()=>{
       setIsPromoteUser(false)
       setSelectedUser('')
      }

      const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            console.error('Invalid date:', dateString);
            return 'Invalid Date'; 
        }
    
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }

    const HandleClickPromote = (type,user)=>{
        if(type==="vipUser")
        {
            toast.error("User already has VIP Membership")
            return
        }
        setIsPromoteUser(true)
        setSelectedUser(user)
    
       }

       const HandleDelete = async(id)=>{
        try {
            setLoading(true);
            await axios.delete(`${backend_url}/api/user/${id}`);
            toast.success("User Deleted successfully");
            dispatch(fetchUsers())
        } catch (error) {
            const errorMessage = error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    
       }


    return (
        <>
            <UserLayout>
                <Section>
                    {
                        loading ? (
                            <LoaderComp/>
                        ) :
                        (
                            <div className="md:px-14 px-5 min-h-screen">
                            <h1 className="text-2xl font-bold text-white py-8">User Management</h1>
                            <h2 className="text-xl font-semibold text-[#9F9F9F] mb-2">Users</h2>
                            <div className="overflow-x-auto">
                                <table className="w-full text-white rounded-lg">
                                    <thead>
                                        <tr className="text-left bg-[#141414] border-b border-[#9F9F9F]">
                                            <th className="p-3">Name</th>
                                            <th className="p-3">Email</th>
                                            <th className="p-3">Credits</th>
                                            <th className="p-3">Status</th>
                                            <th className="p-3">Registration date</th>
                                            <th className="p-3">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="space-y-2">
                                        {users.map((user, index) => (
                                            <React.Fragment key={index}>
                                                <tr className="bg-[#141414] text-[#9F9F9F] rounded-lg cursor-pointer" >
                                                    <td onClick={()=>handleClick(user._id)} className="p-4">{user.fullName}</td>
                                                    <td onClick={()=>handleClick(user._id)} className="p-4">{user.email}</td>
                                                    <td onClick={()=>handleClick(user._id)} className="p-4">{user?.Credits || '0'}</td>
                                                    <td onClick={()=>handleClick(user._id)} className="p-4">{user?.userType}</td>
                                                    <td onClick={()=>handleClick(user._id)} className="p-4">{formatDate(user?.createdAt) || '10-06-2023'}</td>
                                                    <td className="p-4 flex gap-2">
                                                        <button onClick={()=>{
                                                            setSelectedUser(user)
                                                            setIsEditUser(true)
                                                            }}>
                                                            <img src={edit} alt='' />
                                                        </button>
                                                        <button onClick={()=>HandleDelete(user._id)}>
                                                            <img src={trash} alt='' />
                                                        </button>
                                                        <button onClick={()=>{
                                                            HandleClickPromote(user.userType,user)
                                                            
                                                        }}
                                                        disabled={user.userType==="vipUser"}
                                                         className={`px-3 py-1 ${user.userType==="vipUser" ? "border border-red-500" : "bg-red-500 hover:bg-red-600"}  text-white rounded-lg `}>
                                                            {user.userType==="vipUser" ? "Promoted" : "Promote"}
                                                        </button>
                                                    </td>
                                                </tr>
                                                {index < users.length - 1 && <tr className="h-4 rounded-lg bg-black"></tr>}
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <EditUserModal isOpen={isEditUser} onClose={HandleCloseEdit} user={selectedUser} setSelectedUser={setSelectedUser}/>
                            <PromoteUserModel isOpen={ispromoteUser} onClose={HandleClosePromote} user={selectedUser} setSelectedUser={setSelectedUser}/>
                        </div>
                        )
                    }
                  
                </Section>
            </UserLayout>
        </>
    )
}

export default UserManagement;
