import React, { useEffect, useState } from 'react';
import musicIcon from '../assets/musicIcon.svg';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateBeatId, updateCategory } from '../redux/analyticsSlice';

// Function to calculate time units
const calculateTimeUnits = (endTime) => {
    const now = new Date().getTime();
    const distance = new Date(endTime).getTime() - now;

    if (distance < 0) {
        return [
            { label: 'Days', value: '00' },
            { label: 'Hours', value: '00' },
            { label: 'Min', value: '00' },
            { label: 'Sec', value: '00' }
        ];
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return [
        { label: 'Days', value: days.toString().padStart(2, '0') },
        { label: 'Hours', value: hours.toString().padStart(2, '0') },
        { label: 'Min', value: minutes.toString().padStart(2, '0') },
        { label: 'Sec', value: seconds.toString().padStart(2, '0') }
    ];
};

const CountdownTimer = ({ endTime }) => {
    const [timeUnits, setTimeUnits] = useState(calculateTimeUnits(endTime));

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeUnits(calculateTimeUnits(endTime));
        }, 1000);

        return () => clearInterval(intervalId);
    }, [endTime]);

    return (
        <div className="flex flex-col items-center py-3">
            <div className="flex items-center gap-2">
                {timeUnits.map((unit, index) => (
                    <React.Fragment key={index}>
                        <div className="text-center py-2 flex flex-col gap-1">
                            <div className="text-sm px-2 py-1 bg-[#FF3E41] rounded-sm">{unit.value}</div>
                            <div className="text-xs text-[#FF3E41]">{unit.label}</div>
                        </div>
                        {index < timeUnits.length - 1 && (
                            <div className="relative bottom-2 text-[#FF3E41]">:</div>
                        )}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

const Cards = ({ beat, onEditClick }) => {
    const navigate = useNavigate(); 
    const dispatch = useDispatch(); 

    const handleClick = () => {
        dispatch(updateCategory('beats'));
        dispatch(updateBeatId(beat._id));
        navigate('/admin/analytics');}
    return (
        <div className="flex flex-col items-center py-3" >
            {beat.beatType === 'Exclusive' && (
                <CountdownTimer endTime={beat.countdownEndTime} />
            )}
            <div className="bg-[#141414] rounded-xl py-5 w-full">
                <div className="flex py-2 justify-center" onClick={handleClick}>
                    <div>
                        <img src={musicIcon} alt="Music Icon" />
                    </div>
                </div>
                <div className="text-center flex flex-col gap-1"onClick={handleClick}>
                    <div className="text-xl">{beat.beatTitle}</div>
                    <div className="text-sm font-medium text-[#9F9F9F]">{beat.artistName}</div>
                    <div className="text-sm text-[#9F9F9F]">{beat.bpm} BPM</div>
                </div>
                <div className="flex justify-center gap-1" onClick={handleClick}>
                    {beat.tags.map((tag, index) => (
                        <span key={index} className="bg-black text-white p-1 rounded text-xs">
                            #{tag}
                        </span>
                    ))}
                </div>
                <div className="border-t border-t-[#9F9F9F] mx-7 my-2"></div>
                <div className="text-center mb-4">
                    <div className="text-green-500 text-xl" onClick={handleClick}>${beat.price}</div>
                </div>
                <div className="flex justify-center w-full">
                    <button
                        onClick={() => onEditClick(beat)}
                        className="bg-[#FF3E41] hover:scale-110 hover:transition-transform duration-200 px-4 w-32 py-2 rounded-md"
                    >
                        Edit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Cards;