import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import { backend_url } from '../../libs/data';
import { useDispatch } from 'react-redux';
import { fetchBeats } from '../../redux/beatSlice';

export const BeatEditModel = ({ beats, setOpenEditBeatModel  }) => {
   
    
    const [beatTitle, setBeatTitle] = useState(beats?.beatTitle || '');
    const [bpm, setBpm] = useState(beats?.bpm || '');
    const [mp3FileLink, setMp3FileLink] = useState(beats?.mp3FileLink || '');
    const [wavFileLink, setWavFileLink] = useState(beats?.wavFileLink || '');
    const [zipFileLink, setZipFileLink] = useState(beats?.zipFileLink || '');
    const [releaseDate, setReleaseDate] = useState(beats?.releaseDate || '');
    const [price, setPrice] = useState(beats?.price || '');
    const [tags, setTags] = useState(beats?.tags || []);
    const [beatType, setBeatType] = useState(beats?.beatType || '');
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [countdownEndTime, setCountdownEndTime] = useState(beats?.countdownEndTime || '');
    const [staticCountdown, setStaticCountdown] = useState(null);
    const [streamingMp3FileLink, setStreamingMp3FileLink] = useState(beats?.streamingMp3FileLink);

    const dispatch = useDispatch()

    useEffect(() => {
        if (countdownEndTime) {
            const now = new Date().getTime();
            const endTime = new Date(countdownEndTime).getTime();
            const distance = endTime - now;

            if (distance > 0) {
                const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);

                setStaticCountdown({
                    days,
                    hours,
                    minutes,
                    seconds
                });
            } else {
                setStaticCountdown({
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0
                });
            }
        }
    }, [countdownEndTime]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();  
            const newTag = inputValue.trim();

            if (newTag && !tags.includes(newTag)) {
                setTags([...tags, newTag]);
                setInputValue('');
            }
        }
    };

    const validateForm = () => {
        let errors = {};

        if (!beatTitle) errors.beatTitle = 'Beat title is required';
        if (!bpm || isNaN(bpm)) errors.bpm = 'BPM must be a number';
        if (!mp3FileLink && !wavFileLink && !zipFileLink) {
            errors.fileLink = 'At least one file link is required';
        } else {
            if (mp3FileLink && !/^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/.test(mp3FileLink)) {
                errors.mp3FileLink = 'Invalid URL';
            }
            if (wavFileLink && !/^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/.test(wavFileLink)) {
                errors.wavFileLink = 'Invalid URL';
            }
            if (zipFileLink && !/^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/.test(zipFileLink)) {
                errors.zipFileLink = 'Invalid URL';
            }
        }
        if (!releaseDate) errors.releaseDate = 'Release date is required';
        if (beatType === 'Exclusive' && (!price || isNaN(price))) errors.price = 'Price must be a number';

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleRemoveTag = (tagToRemove) => {
        setTags(tags.filter(tag => tag !== tagToRemove));
    };

    const handleSubmit = async(event) => {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }

        const beatsData = {
            beatTitle,
            bpm,
            mp3FileLink,
            wavFileLink,
            zipFileLink,
            releaseDate,
            tags,
            price,
            beatType,
            countdownEndTime,
            streamingMp3FileLink
        };

        try {
            setLoading(true);
            await axios.post(`${backend_url}/api/updatebeats/${beats?._id}`, { beatsData });
            toast.success("Beat Updated Successfully");
            dispatch(fetchBeats())
        } catch (error) {
            const errorMessage = error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
            toast.error(errorMessage);
          
        } finally {
            setLoading(false);
            setOpenEditBeatModel(false);
        }
    };

    const handleFocus = (field) => {
        setErrors(prevErrors => ({ ...prevErrors, [field]: null }));
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-[#141414] min-h-[80%] max-h-[80%] w-[30%] p-6 rounded-lg m-6 overflow-y-auto custom-scrollbar text-black">
                <div className="flex items-center justify-between">
                    <div></div>
                    <h1 className='text-white font-bold flex text-center'>Update Beat</h1>
                    <FaTimes onClick={() => setOpenEditBeatModel(false)} className='cursor-pointer text-white' />
                </div>
                <h2 className="text-base text-white py-3 text-center font-bold">{`${beatType} Beat Form`}</h2>
                <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-1">
                        <label className='text-[#FFFFFF]'>Beat Title</label>
                        <input
                            type="text"
                            placeholder="Enter beat title"
                            className="p-2 border rounded-md"
                            value={beatTitle}
                            onFocus={() => handleFocus("beatTitle")}
                            onChange={(e) => setBeatTitle(e.target.value)}
                        />
                        {errors.beatTitle && <p className="text-red-500 text-sm">{errors.beatTitle}</p>}
                    </div>
                  
                    <div className="flex flex-col gap-1">
                        <label className='text-[#FFFFFF]'>BPM</label>
                        <input
                            type="text"
                            placeholder="Enter BPM"
                            className="p-2 border rounded-md"
                            value={bpm}
                            onChange={(e) => setBpm(e.target.value)}
                            onFocus={() => handleFocus("bpm")}
                        />
                        {errors.bpm && <p className="text-red-500 text-sm">{errors.bpm}</p>}
                    </div>
                    <div className="flex flex-col gap-1">
                        <label className='text-[#FFFFFF]'>.MP3 File Link</label>
                        <input
                            type="text"
                            placeholder="Enter MP3 file link"
                            className="p-2 border rounded-md"
                            value={mp3FileLink}
                            onFocus={() => handleFocus("mp3FileLink")}
                            onChange={(e) => setMp3FileLink(e.target.value)}
                        />
                        {errors.mp3FileLink && <p className="text-red-500 text-sm">{errors.mp3FileLink}</p>}
                    </div>
                    <div className="flex flex-col gap-1">
                        <label className='text-[#FFFFFF]'>.WAV File Link</label>
                        <input
                            type="text"
                            placeholder="Enter WAV file link"
                            className="p-2 border rounded-md"
                            value={wavFileLink}
                            onFocus={() => handleFocus("wavFileLink")}
                            onChange={(e) => setWavFileLink(e.target.value)}
                        />
                        {errors.wavFileLink && <p className="text-red-500 text-sm">{errors.wavFileLink}</p>}
                    </div>
                    <div className="flex flex-col gap-1">
                        <label className='text-[#FFFFFF]'>.ZIP File Link</label>
                        <input
                            type="text"
                            placeholder="Enter ZIP file link"
                            className="p-2 border rounded-md"
                            value={zipFileLink}
                            onFocus={() => handleFocus("zipFileLink")}
                            onChange={(e) => setZipFileLink(e.target.value)}
                        />
                        {errors.zipFileLink && <p className="text-red-500 text-sm">{errors.zipFileLink}</p>}
                    </div>
                    <div className="flex flex-col gap-1">
                        <label className='text-[#FFFFFF]'>Streaming mp3 Url</label>
                        <input
                            type="text"
                            placeholder="Streaming Link"
                            className="p-2 border rounded-md"
                            value={streamingMp3FileLink}
                            disabled
                        />
                    </div>
                    <div className="flex flex-col gap-1">
                        <label className='text-[#FFFFFF]'>Release Date</label>
                        <input
                            type="date"
                            className="p-2 border rounded-md"
                            value={releaseDate}
                            onFocus={() => handleFocus("releaseDate")}
                            onChange={(e) => setReleaseDate(e.target.value)}
                        />
                        {errors.releaseDate && <p className="text-red-500 text-sm">{errors.releaseDate}</p>}
                    </div>
                   { beatType==="Exclusive" &&
                    (<div className="flex flex-col gap-1">
                        <label className='text-[#FFFFFF]'>Countdown End Time</label>
                        <input
                            type="datetime-local"
                            className="p-2 border rounded-md"
                            value={countdownEndTime}
                            onFocus={() => handleFocus("countdownEndTime")}
                            onChange={(e) => setCountdownEndTime(e.target.value)}
                        />
                        {errors.countdownEndTime && <p className="text-red-500 text-sm">{errors.countdownEndTime}</p>}
                    </div>
                    )}
                    <div className="flex flex-col gap-1">
                        <label className='text-[#FFFFFF]'>Tags</label>
                        <input
                            type="text"
                            placeholder="Enter tags"
                            className="p-2 border rounded-md"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyDown={handleKeyDown}
                            onFocus={() => handleFocus('tags')}
                        />
                        <div className="flex flex-wrap gap-1">
                            {tags.map((tag, index) => (
                                <div
                                    key={index}
                                    className="bg-gray-200 text-gray-800 px-2 py-1 rounded-full flex items-center gap-1"
                                >
                                    {tag}
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleRemoveTag(tag);
                                        }}
                                        className="text-red-500 hover:text-red-700"
                                    >
                                        &times;
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                    {beatType === 'Exclusive' && (
                        <div className="flex flex-col gap-1">
                            <label className='text-[#FFFFFF]'>Price</label>
                            <input
                                type="text"
                                placeholder="Enter price"
                                className="p-2 border rounded-md"
                                value={price}
                                onFocus={() => handleFocus('price')}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                            {errors.price && <p className="text-red-500 text-sm">{errors.price}</p>}
                        </div>
                    )}
                     {beatType === "Exclusive" && staticCountdown && (
                        <div className="mt-4 text-center text-white">
                            <p className="text-lg font-bold">Countdown:</p>
                            <p>{`${staticCountdown.days}d ${staticCountdown.hours}h ${staticCountdown.minutes}m ${staticCountdown.seconds}s`}</p>
                        </div>
                    )}
                    <div className="py-2">
                        <button type="submit" className="bg-red-500 w-full text-white py-2 px-4 rounded-md hover:bg-red-600" disabled={loading}>
                            {loading ? 'Updating...' : 'Update'}
                        </button>
                    </div>
                   
                </form>
            </div>
        </div>
    );
};
