import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { backend_url } from '../../libs/data';
import { useDispatch } from 'react-redux';
import { fetchUsers } from '../../redux/userSlice';

const PromoteUserModel = ({ isOpen, onClose,user,setSelectedUser }) => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();


    const handleSubmit = async(e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const response = await axios.post(`${backend_url}/api/updatetype`, {
                userId:user._id,
                newType:"vipUser"
            });
            toast.success("user Promoted");
            dispatch(fetchUsers())
        } catch (error) {
            const errorMessage = error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
            toast.error(errorMessage);
        } finally {
            setLoading(false);
            onClose();
        }
       
    };

    if (!isOpen) return null;

    return (
        <div className="absolute inset-0 flex items-center z-50 justify-center text-white bg-black bg-opacity-50 backdrop-blur-sm">
            <div className="p-6 bg-[#141414] rounded-lg w-full max-w-sm">
                <div className="flex py-3 gap-8 items-center justify-center">
                    <h2 className="text-xl font-bold text-center">Promote User</h2>
                    <FaTimes onClick={()=>{
                        onClose()
                        setSelectedUser('')
                    }} className='cursor-pointer relative lg:left-24 flex justify-end text-end text-white' />
                </div>
                <form onSubmit={handleSubmit} className='flex flex-col gap-2'>
                    <div className="pb-5">
                        <label htmlFor="email" className="block text-sm font-medium p-1">Email</label>
                        <input
                            type="email"
                            id="email"
                            disabled
                            value={user.email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full border text-black rounded-md p-2"
                            required
                        />
                    </div>
                    <div className="text-xl flex justify-between items-center gap-10">
                       <p>User Type is:</p>
                       <p className='bg-red-500 p-2 rounded-md'>{user?.userType}</p>
                    </div>
                   
                    <button
                        type="submit"
                        className="w-full  bg-red-500 text-white py-2 mt-5 px-4 rounded-md hover:bg-red-600"
                    >
                        {loading ? "Promoting..." : "Promote User to VIP"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default PromoteUserModel;
