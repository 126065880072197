import React, { useState } from 'react'
import ChartsCards from '../../components/ChartsCards'
import UserLayout from '../../layout/UserLayout'
import Section from '../../components/Section';

const Dashboard = () => {
    const [showAllGlobal, setShowAllGlobal] = useState(false);
    const [showAllBeats, setShowAllBeats] = useState(false);
    const toggleShowMoreGlobal = () => setShowAllGlobal(!showAllGlobal);
    const toggleShowMoreBeats = () => setShowAllBeats(!showAllBeats);
    return (
        <>
            <UserLayout>
                <Section>
                    <div className="min-h-screen px-5 md:px-10 bg-black">
                        <div className="md:px-7 py-8">
                            <h1 className="text-2xl font-bold text-white">Dashboard</h1>
                        </div>
                        <div className="flex py-4 px-7 justify-between">
                            <h2 className="text-xl text-[#9F9F9F]">Global</h2>
                            <button onClick={toggleShowMoreGlobal} className="text-[#9F9F9F] hover:bg-[#9F9F9F] hover:text-white px-2 py-1">
                                {showAllGlobal ? 'See Less' : 'See More'}
                            </button>
                        </div>
                        <ChartsCards category="global" showAll={showAllGlobal} />
                        <div className="flex py-4 px-7 justify-between">
                            <h2 className="text-xl text-[#9F9F9F]">Beats</h2>
                            <button onClick={toggleShowMoreBeats} className="text-[#9F9F9F] hover:bg-[#9F9F9F] hover:text-white px-2 py-1">
                                {showAllBeats ? 'See Less' : 'See More'}
                            </button>
                        </div>
                        <ChartsCards category='beats' />
                    </div>
                </Section>
            </UserLayout>
        </>
    )
}

export default Dashboard